import { defineStore } from "pinia";
import moment from "moment/moment";
import type { CartItem } from "~/types/componentProps/cart";
import { PizzaSize, type ProductEntity } from "~/types/entities/product.type";
import type {
  _Product,
  ValidateCartItem,
  ValidateProductResponse,
  ValidationAndCheckoutErrorDetails,
} from "~/types/entities/cartValidation.type";
import useCheckoutApi from "~/composables/api/useCheckoutApi";
import { useLocationSlice } from "~/store/locationSlice";
import {
  HandoverType,
  OrderMethod,
  PaymentUserType,
  useOrderMethodStore,
} from "~/store/orderMethodSlice";
import { useAuthSlice } from "~/store/authSlice";

interface CartSliceState {
  showCart: boolean;
  cartList: CartItem[];
  dummyTotalPrice: ValidateProductResponse | object;
  deliveryFee: number;
  discount: number;
  couponCode: { key: string; isValid: boolean | undefined };
  checkoutErrors: ValidationAndCheckoutErrorDetails | null;
}

export const useCartSlice = defineStore("cartSlice", {
  state: (): CartSliceState => {
    return {
      showCart: false,
      cartList: [],
      dummyTotalPrice: {},
      deliveryFee: 0,
      discount: 0,
      couponCode: {
        key: "",
        isValid: undefined,
      },
      checkoutErrors: null,
    };
  },
  actions: {
    toggleCartShow() {
      this.showCart = !this.showCart;
    },
    createCartAddableItem(product: ProductEntity | null): CartItem | null {
      if (!product) {
        return null;
      }
      return {
        productId: product.id,
        quantity: 1,
        product: {
          ...product,
          isAvailable: product.isAvailable,
          size: product.size ? (product.size === "LARGE" ? PizzaSize.LARGE : PizzaSize.SMALL) : "",
          selectedSize: PizzaSize.LARGE,
          customizableOptions: {
            includedItem: [],
            excludedItems: [],
          },
          secondHalf: null,
        },
      };
    },
    mapCartAndValidationResponse(_product: _Product) {
      const cartedProduct = this.cartList.find((i, index) => {
        return i.productId === _product.productNr && _product.id === index;
      });
      if (cartedProduct) {
        cartedProduct.quantity = _product.quantity;
        cartedProduct.product.price = _product.price.fullItemPrice / _product.quantity;
        cartedProduct.product.isAvailable = _product.isAvailable;
        if (cartedProduct.product.secondHalf) {
          cartedProduct.product.secondHalf.price =
            (_product.secondHalf?.price.fullItemPrice ?? 0) / _product.secondHalf.quantity;
        }
        cartedProduct.product.customizableOptions.includedItem.forEach((item) => {
          const _subItem = _product.subItems.find((i) => i.productNr === item.id);
          console.log(_subItem);
          if (_subItem) {
            item.quantity = _subItem.quantity;
            item.price = _subItem.price.fullPrice / _subItem.quantity;
            item.isAvailable = _subItem.isAvailable;
          }
        });
      }
    },
    async validateCartItem(validateCoupon = false) {
      const route = useRoute();
      const { validateCartItem } = useCheckoutApi();
      const locationSlice = useLocationSlice();
      const orderMethodStore = useOrderMethodStore();
      const { selectedRestaurant } = storeToRefs(locationSlice);
      const autheStore = useAuthSlice();
      const { user, selectedBusinessUser } = storeToRefs(autheStore);
      const {
        selectedMethod,
        selectedLocation,
        selectedRestaurantForDelivery,
        timeOfHandover,
        formatedHandoverTime,
        paymentUserType,
      } = storeToRefs(orderMethodStore);
      const data: ValidateCartItem = {
        userId: user.value?.customerId ?? 0,
        businessUserId:
          paymentUserType.value === PaymentUserType.BUSINESS && selectedBusinessUser.value
            ? selectedBusinessUser.value.customerId
            : 0,
        orderType: selectedMethod.value,
        deliveryTime:
          timeOfHandover.value.type === HandoverType.SCHEDULED
            ? moment(formatedHandoverTime.value).local().utc().toISOString()
            : null,
        deliveryAddress:
          selectedMethod.value === OrderMethod.DELIVERY
            ? {
                streetName: selectedLocation.value?.adressenavn || "",
                streetNr: selectedLocation.value?.nummer || 0,
                postalCode: selectedLocation.value?.postnummer || "",
                stairWay: selectedLocation.value?.bokstav || "",
                floor: null,
                addressDescription: null,
                longDescription: null,
              }
            : null,
        restaurantCode:
          selectedMethod.value === OrderMethod.DELIVERY
            ? selectedRestaurantForDelivery.value?.code || "00"
            : selectedRestaurant.value?.code || "00",
        totalPrice: 0,
        context: route.path === "/checkout" ? "Checkout" : "ShoppingCart",
        discountCode:
          (this.couponCode.isValid === undefined || this.couponCode.isValid === true) &&
          this.couponCode.key &&
          paymentUserType.value !== PaymentUserType.BUSINESS
            ? this.couponCode.key
            : null,
        products: this.cartList.map((item, index) => {
          return {
            id: index,
            productNr: item.productId,
            quantity: item.quantity,
            isHalfBaked: item.product.orderAsHalfBaked ?? false,
            price: {
              amount: 0,
              vatPrct: 0,
            },
            extraItem: false,
            isHalfnHalf: !!item.product.secondHalf,
            secondHalf: item.product.secondHalf
              ? {
                  id: 1,
                  productNr: item.product.secondHalf.id,
                  quantity: item.quantity,
                  price: {
                    amount: 0,
                    vatPrct: 0,
                  },
                  subItems: [],
                  extraItem: false,
                  removableItem: false,
                  changeable: false,
                }
              : null,
            isDeal: item.product.isDeal,
            subItems: item.product.customizableOptions.includedItem
              .map((si, ind) => {
                return {
                  id: ind,
                  productNr: si.id,
                  quantity: si.quantity ?? 1,
                  price: {
                    amount: 0,
                    vatPrct: 0,
                  },
                  subItems: [],
                  extraItem: si.hasExtra,
                  removableItem: si.isRemovable,
                  changeable: si.isChangeable,
                };
              })
              .concat(
                item.product.customizableOptions.excludedItems.map((it, index) => {
                  return {
                    id: index + item.product.customizableOptions.includedItem.length,
                    productNr: it.id,
                    quantity: it.quantity ?? 1,
                    price: {
                      amount: 0,
                      vatPrct: 0,
                    },
                    subItems: [],
                    extraItem: it.hasExtra,
                    removableItem: it.isRemovable,
                    changeable: it.isChangeable,
                  };
                }),
              ),
          };
        }),
      };
      if (validateCoupon) {
        return await validateCartItem(data, true);
      } else {
        const res = await validateCartItem(data);
        if (res && !res.errorDetails) {
          this.deliveryFee = res.deliveryCharge;
          res.products.forEach((product) => {
            this.mapCartAndValidationResponse(product);
          });
          this.discount = res.totalDiscount;
        }
        this.checkoutErrors = res?.errorDetails || null;
        return res;
      }
    },
    addToCart(cartItem: CartItem) {
      this.cartList.push(cartItem);
      this.validateCartItem();
      return this.cartList.length - 1;
    },
    increaseQuantity(index: number) {
      this.cartList[index].quantity++;
      this.validateCartItem();
    },
    decreaseQuantity(index: number) {
      this.cartList[index].quantity--;
      this.validateCartItem();
    },
    removeItemFromCart(index: number) {
      const { logGoogleEventWithMiddleWare } = useConsentTracking();
      logGoogleEventWithMiddleWare("remove_from_cart", {
        productId: this.cartList[index].productId.toString(),
        productName: this.cartList[index].product.name.toString(),
      });
      this.cartList.splice(index, 1);
      this.validateCartItem();
    },
    clearCart() {
      this.cartList = [];
      this.dummyTotalPrice = {};
    },
    clearCouponCode() {
      this.couponCode = {
        isValid: undefined,
        key: "",
      };
    },
  },
  getters: {
    cartLength(state) {
      return state.cartList.reduce((acc, cur) => {
        return acc + cur.quantity;
      }, 0);
    },
    cartTotalPrice(state) {
      return state.cartList.reduce((acc, cur) => {
        return (
          acc +
          (cur.product.price +
            (cur.product.secondHalf ? cur.product.secondHalf.price : 0) +
            cur.product.customizableOptions.includedItem.reduce((a, c) => {
              return a + c.price;
            }, 0)) *
            cur.quantity
        );
      }, 0);
    },
    activeItemsInCart(state) {
      return state.cartList.filter((ci) => ci.quantity > 0);
    },
    getProductRefFromCart(state) {
      return (id: string) => {
        return state.cartList.find((ci) => ci.productId === id) ?? null;
      };
    },
    getDeliveryCharge(state) {
      return state.deliveryFee;
    },
  },
  persist: {
    storage: persistedState.localStorage,
  },
});

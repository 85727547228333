import moment from "moment";
import { HandoverType, OrderMethod, useOrderMethodStore } from "~/store/orderMethodSlice";
import { useLocationSlice } from "~/store/locationSlice";

export function useAutoSchedule() {
  const locationSlice = useLocationSlice();
  const { selectedRestaurant } = storeToRefs(locationSlice);
  const orderMethodStore = useOrderMethodStore();
  const { selectedMethod, selectedRestaurantForDelivery } = storeToRefs(orderMethodStore);
  const currentRestaurant = computed(() => {
    if (selectedMethod.value === OrderMethod.DELIVERY) {
      return selectedRestaurantForDelivery.value?.orderTypeInfo.find((i) => i.code === "L");
    } else if (selectedMethod.value === OrderMethod.PICKUP) {
      return selectedRestaurant.value?.orderTypeInfo.find((i) => i.code === "T");
    }
    return null;
  });
  const isAsSoonAsPossible = computed(() => {
    const currentTime = moment();
    if (!currentRestaurant.value) {
      return false;
    } else {
      const openingTime = moment(currentRestaurant.value.openingHours.openingTime, "HH:mm");
      const closingTime = moment(currentRestaurant.value.openingHours.closingTime, "HH:mm");
      return !(currentTime.isBefore(openingTime) || currentTime.isAfter(closingTime));
    }
  });
  const proceedAutoScheduleIfNeeded = () => {
    if (currentRestaurant.value) {
      const currentTime = moment();
      const openingTime = moment(currentRestaurant.value.openingHours.openingTime, "HH:mm");
      const closingTime = moment(currentRestaurant.value.openingHours.closingTime, "HH:mm");
      if (currentTime.isBefore(openingTime)) {
        orderMethodStore.timeOfHandover.type = HandoverType.SCHEDULED;
        const date = moment().format("YYYY-MM-DD").toString();
        const time = openingTime
          .add(preparationTimeForCurrentRestaurant.value, "minutes")
          .format("HH:mm")
          .toString();
        orderMethodStore.setDateAndTime(date, time);
      } else if (currentTime.isAfter(closingTime)) {
        orderMethodStore.timeOfHandover.type = HandoverType.SCHEDULED;
        const date = moment().add(1, "day").format("YYYY-MM-DD").toString();
        const day = moment(date).format("dddd").toLowerCase();
        const foundTime = currentRestaurant.value.openingHoursOfTheWeek.find(
          (i) => i.day.toLowerCase() === day,
        )?.openingTime;
        const time = moment(foundTime, "HH:mm")
          .add(preparationTimeForCurrentRestaurant.value, "minutes")
          .format("HH:mm")
          .toString();

        console.log({ date, time });
        if (date && time) {
          orderMethodStore.setDateAndTime(date, time);
        }
      } else {
        orderMethodStore.timeOfHandover.type = HandoverType.ASSOONASPOSSIBLE;
      }
    }
  };
  const preparationTimeForCurrentRestaurant = computed(() => {
    if (currentRestaurant.value) {
      return currentRestaurant.value.deliveryTime;
    }
    return 0;
  });
  return {
    isAsSoonAsPossible,
    proceedAutoScheduleIfNeeded,
    preparationTimeForCurrentRestaurant,
  };
}

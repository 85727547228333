/**
 * it's a mocking of menu page api
 * @returns {unknown}
 */
import { useGet } from "~/composables/api/customFetch/useCustomFetch";
import type { NavigatorType } from "~/types/entities/navigatorProducts.type";
import type {
  customizationOptionResponse,
  ProductEntity,
  ProductsResponse,
} from "~/types/entities/product.type";
import type { RestaurantType } from "~/types/entities/restaurant";
import { useLocationSlice } from "~/store/locationSlice";
import { OrderMethod, useOrderMethodStore } from "~/store/orderMethodSlice";

const useMenu = () => {
  /**
   * it will be used to return all the products name
   * @returns {Promise<NavigatorType[]>} - all the products name
   */
  const getAllProductsName = async (): Promise<NavigatorType[]> => {
    try {
      const { data } = await useGet<NavigatorType[]>("/products/categories-v2");
      return data.value ?? [];
    } catch (e) {
      console.log(e);
      return [];
    }
  };

  /**
   * it will be used to return all the products by name
   * @param {string} name - the name of the product
   * @returns {Promise<MockProductType[]>} - all the products by name
   */
  const getAllProductsByName = async (name: string): Promise<ProductsResponse[]> => {
    try {
      const locationsStore = useLocationSlice();
      const orderMethodSlice = useOrderMethodStore();
      const { selectedRestaurant } = storeToRefs(locationsStore);
      const { selectedRestaurantForDelivery, selectedMethod } = storeToRefs(orderMethodSlice);
      if (name) {
        const { data } = await useGet<ProductsResponse[]>("/products/productList", {
          query: {
            productGroupId: name,
            RestaurantCode:
              selectedMethod.value === OrderMethod.DELIVERY
                ? selectedRestaurantForDelivery.value?.code
                : selectedRestaurant.value?.code,
          },
        });
        return data.value ?? [];
      }
      return [];
    } catch (e) {
      console.log(e);
      return [];
    }
  };
  /**
   * it will be used to return all the restaurant with location
   * @returns {Promise<RestaurantType[]>} - all the products name
   */
  const getAllRestaurantCoordinate = async (): Promise<RestaurantType[]> => {
    try {
      const { data } = await useGet<RestaurantType[]>("restaurant", { disableGlobalLoader: true });
      return data.value ?? [];
    } catch (e) {
      console.log(e);
      return [];
    }
  };

  const getProductCustomizationOptionById = async (
    productId: string,
  ): Promise<customizationOptionResponse | null> => {
    try {
      const { data } = await useGet<customizationOptionResponse>(
        "products/details-v2?productId=" + productId,
        { disableGlobalLoader: true },
      );
      return data.value ?? null;
    } catch (e) {
      return null;
    }
  };
  const getProductVariantPrice = async (productId: string): Promise<number> => {
    try {
      const { data } = await useGet<number>(`products/${productId}/price`, {
        disableGlobalLoader: true,
      });
      return data.value ?? 0;
    } catch (e) {
      console.log(e);
      return 0;
    }
  };
  const getUpSaleProduct = async (): Promise<ProductEntity[]> => {
    try {
      const { data } = await useGet<ProductEntity[]>("/products/upsale", {
        disableGlobalLoader: true,
      });
      return data.value ?? [];
    } catch (e) {
      console.log(e);
      return [];
    }
  };
  const searchProducts = async (searchTerm: string): Promise<ProductsResponse[]> => {
    try {
      const { data } = await useGet<ProductsResponse[]>(
        "/products/productList/search/" + searchTerm,
        {
          disableGlobalLoader: true,
        },
      );
      return data.value ?? [];
    } catch (e) {
      console.log(e);
      return [];
    }
  };
  return {
    getAllProductsName,
    getAllProductsByName,
    getAllRestaurantCoordinate,
    getProductCustomizationOptionById,
    getProductVariantPrice,
    getUpSaleProduct,
    searchProducts,
  };
};
export default useMenu;

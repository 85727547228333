import { defineStore } from "pinia";
import moment from "moment";
import type { PlaceRecommendation } from "~/types/entities/placeRecommendation.type";
import { useOrderConfirmation } from "~/composables/api/useOrderConfirmation";
import type { RestaurantType } from "~/types/entities/restaurant";
import { useCartSlice } from "~/store/cartSlice";
import { useLocationSlice } from "~/store/locationSlice";
import { useAuthSlice } from "~/store/authSlice";
import { getAsapTimeAccordingToSelectedRestaurant } from "~/utils/dateTime.util";
import { useMenuSlice } from "~/store/menuSlice";

export enum OrderMethod {
  DELIVERY = "L",
  PICKUP = "T",
}

export enum PaymentMethod {
  VIPPS,
  SWEDBANK,
}

export enum HandoverType {
  ASSOONASPOSSIBLE,
  SCHEDULED,
}

export enum PaymentUserType {
  PERSONAL = "PERSONAL",
  BUSINESS = "BUSINESS",
}

export enum NotificationOptionType {
  NO_NOTICE = "none",
  CALL = "phone",
  SMS = "sms",
}

interface OrderMethodState {
  liveOrders: number[];
  selectedMethod: OrderMethod;
  selectedLocation: Partial<PlaceRecommendation> | null;
  selectedRestaurantForDelivery: RestaurantType | null;
  paymentMethod: string;
  paymentMethodsPopUp: boolean;
  timeOfHandover: {
    type: HandoverType;
    schedule: {
      date: string;
      time: string;
    };
  };
  whenModalView: boolean;
  paymentUserType: PaymentUserType;
  businessUserPopup: boolean;
  revalidateBusinessUserPopUp: boolean;
  selectedNotificationOption: NotificationOptionType;
  notificationOptionsPopup: boolean;
  selectedNotificationContactNumber: string;
  saveAddressWhileCheckingOut: boolean;
}

export const useOrderMethodStore = defineStore("orderMethodStore", {
  state: (): OrderMethodState => {
    return {
      liveOrders: [],
      selectedMethod: OrderMethod.PICKUP,
      selectedLocation: null,
      selectedRestaurantForDelivery: null,
      paymentMethod: "",
      paymentMethodsPopUp: false,
      timeOfHandover: {
        type: HandoverType.ASSOONASPOSSIBLE,
        schedule: {
          date: "",
          time: "",
        },
      },
      paymentUserType: PaymentUserType.PERSONAL,
      whenModalView: false,
      businessUserPopup: false,
      revalidateBusinessUserPopUp: false,
      selectedNotificationOption: NotificationOptionType.NO_NOTICE,
      notificationOptionsPopup: false,
      selectedNotificationContactNumber: "",
      saveAddressWhileCheckingOut: false,
    };
  },
  actions: {
    setOrderMethod(methodType: OrderMethod) {
      const menuSlice = useMenuSlice();
      const cartSlice = useCartSlice();
      const { cartLength } = storeToRefs(cartSlice);
      this.selectedMethod = methodType;
      if (cartLength.value > 0) {
        if (
          methodType === OrderMethod.DELIVERY &&
          this.selectedRestaurantForDelivery &&
          this.selectedLocation
        ) {
          cartSlice.validateCartItem();
        } else if (methodType === OrderMethod.PICKUP) {
          cartSlice.validateCartItem();
        }
      }
      menuSlice.getAllProductsByName();
    },
    setSelectedLocation(place: Partial<PlaceRecommendation>) {
      this.selectedLocation = place;
    },
    setSelectedRestaurantForDelivery(rest: RestaurantType | null) {
      const { proceedAutoScheduleIfNeeded } = useAutoSchedule();
      this.selectedRestaurantForDelivery = rest;
      proceedAutoScheduleIfNeeded();
      const menuSlice = useMenuSlice();
      menuSlice.getAllProductsByName();

      // Validate cart items for newly selected restaurant
      const cartSlice = useCartSlice();
      if (cartSlice.cartList.length > 0) {
        cartSlice.validateCartItem();
      }
    },
    toggleWhenModal() {
      this.whenModalView = !this.whenModalView;
    },
    setDateAndTime(date: string, time: string) {
      this.timeOfHandover.schedule.date = date;
      this.timeOfHandover.schedule.time = time;
    },
    async fetchAllLiveOrder() {
      const { getLiveOrders } = useOrderConfirmation();
      const orders = await getLiveOrders();
      console.log(orders);
      this.liveOrders = orders;
    },
    togglePaymentMethodsModal() {
      this.paymentMethodsPopUp = !this.paymentMethodsPopUp;
    },
    setPaymentMethodToPay(id: string) {
      this.paymentMethod = id;
    },
    setPaymentUserType(type: PaymentUserType) {
      this.paymentUserType = type;
    },
    toggleBusinessUserModal() {
      this.businessUserPopup = !this.businessUserPopup;
    },
    toggleRevalidateBusinessUser() {
      this.revalidateBusinessUserPopUp = !this.revalidateBusinessUserPopUp;
    },
    toggleNotificationOptionModal() {
      this.notificationOptionsPopup = !this.notificationOptionsPopup;
    },
  },
  getters: {
    formatedHandoverTime(state) {
      const dateTimeString = `${state.timeOfHandover.schedule.date} ${state.timeOfHandover.schedule.time}`;
      const dateTime = moment(dateTimeString, "YYYY-MM-DD HH:mm");
      return dateTime.format("YYYY-MM-DDTHH:mm:ss.SSS");
    },
    constantTimeAccordingToRestaurant(state) {
      if (state.selectedMethod === OrderMethod.PICKUP) {
        const locationSlice = useLocationSlice();
        const { selectedRestaurant } = storeToRefs(locationSlice);
        if (!selectedRestaurant.value) {
          return 0;
        } else {
          const restaurantTimeObject = selectedRestaurant.value.orderTypeInfo.find(
            (it) => it.code === "T",
          );
          return getAsapTimeAccordingToSelectedRestaurant(restaurantTimeObject);
        }
      }
      if (state.selectedMethod === OrderMethod.DELIVERY) {
        if (!state.selectedRestaurantForDelivery) {
          return 0;
        } else {
          const restaurantTimeObject = state.selectedRestaurantForDelivery.orderTypeInfo.find(
            (it) => it.code === "L",
          );
          return getAsapTimeAccordingToSelectedRestaurant(restaurantTimeObject);
        }
      }
      return 0;
    },
    openingAndClosingTimeAccordingToMethod(state) {
      if (state.selectedMethod === OrderMethod.PICKUP) {
        const locationSlice = useLocationSlice();
        const { selectedRestaurant } = storeToRefs(locationSlice);
        if (!selectedRestaurant.value) {
          return [];
        } else {
          return selectedRestaurant.value.orderTypeInfo.find((it) => it.code === "T")
            ?.openingHoursOfTheWeek;
        }
      }
      if (state.selectedMethod === OrderMethod.DELIVERY) {
        if (!state.selectedRestaurantForDelivery) {
          return [];
        } else {
          return state.selectedRestaurantForDelivery.orderTypeInfo.find((it) => it.code === "L")
            ?.openingHoursOfTheWeek;
        }
      }
      return [];
    },
    selectedPaymentMethod(state) {
      const authStore = useAuthSlice();
      const { paymentCards } = storeToRefs(authStore);
      return paymentCards.value.find((it) =>
        state.paymentMethod ? state.paymentMethod === it.id : it.isDefault,
      );
    },
  },
  persist: {
    storage: persistedState.localStorage,
  },
});

import type { Ref } from "vue";

export enum SpiceLevel {
  High = "High",
  Medium = "Medium",
  Low = "Low",
}

export enum Tag {
  NEW = "New",
  POPULAR = "Popular",
}

interface RatingType {
  rate: number;
  count: number;
}

export default interface ProductType {
  id: string;
  title: string;
  price: number;
  description: string;
  category: string;
  image: string;
  rating: RatingType;
  mine: number;
  tag?: Tag;
  spiceLeve: SpiceLevel;
}

export type ProductAttributeType = "Veg" | "SpicyX2" | "SpicyX3";

export type ProductCardType = Partial<ProductType> & {
  showAddToCart?: boolean;
  new_price?: number;
  order?: "col" | "row";
  size?: "sm" | "lg";
  showAction?: boolean;
  attributes?: ProductAttributeType[];
};
export type MockProductType = {
  id: string;
  img: string;
  name: string;
  dsc: string;
  price: number;
  rate: number;
  country: string;
  tag?: Tag;
  spiceLevel: SpiceLevel;
  isVegan: boolean;
};

export enum PizzaSize {
  SMALL = "Liten",
  LARGE = "Stor",
  GLUTONFREE = "Glutenfri",
}

export type MockProductsResponse = {
  subCategoryName: string;
  productDetails: MockProductType[];
};
export type CustomizationElementType = {
  id: string;
  name: string;
  isAvailable: boolean;
  isRemovable: boolean;
  isStandard: boolean;
  hasExtra: boolean;
  itemTypeId: string;
  itemType: string;
  price: number;
  quantity: number;
  standardItemRef?: string;
  isChangeable: boolean;
  addableItem: CustomizationElementType | null;
  removableItem: CustomizationElementType | null;
  allergens: {
    id: string;
    description: string;
  }[];
};

export type CustomizationOptionsType = {
  productType: string;
  subProductDetails: { [key: string]: CustomizationElementType };
};
export type AddableProductTypeToCart = {
  productId: string;
  quantity: number;
  productToAddToCart: unknown;
};

export enum ProductSize {
  SMALL = "SMALL",
  LARGE = "LARGE",
}

export interface RelatedProduct {
  id: string;
  type: string | null;
  size: ProductSize | null;
  price: number;
}

export interface ProductEntity {
  id: string;
  name: string;
  dsc: string;
  productCode: string;
  productTypeCode: string;
  showOnLine: boolean;
  isAvailable: boolean;
  halfBakedItem: boolean;
  orderAsHalfBaked?: boolean;
  size: PizzaSize | "";
  maxExtraItems: number;
  maxRemovableItems: number;
  maxAdditionalItems: number;
  price: number;
  discount: number;
  spiceLevel: string | null;
  isVegan: boolean;
  tag: string | null;
  img: string;
  key: string;
  productGroupName: string;
  relatedProducts: RelatedProduct[];
  allergens: {
    id: string;
    description: string;
  }[];
  isSplittable: boolean;
  section: {
    sectionId: number;
    sectionDescription: string;
  };
  isDeal: boolean;
  dealInfo: null | {
    dealSteps: {
      description: string;
      sequenceNumber: number;
      dealItems: ProductEntity[];
    }[];
    description: string;
  };
  isCustomizable?: boolean;
}

export type ProductsResponse = {
  subCategoryName: string;
  productDetails: ProductEntity[];
};

export interface SupperCustomizationElementType {
  self: CustomizationElementType;
  includeSelf: () => void;
  excludeSelf: () => void;
  isIncluded: () => Ref<boolean>;
  addAsExtraItem: () => void;
  removeAsExtraItem: () => void;
  myExtraIncludedItems: () => Ref<boolean>;
  myPrice: () => Ref<number>;
  myAllergens: () => Ref<string>;
}

export type SupperCustomizationOptionsType = {
  productType: string;
  subProductDetails: SupperCustomizationElementType[];
};
export type CustomizationsOptionsTypeInDetails = {
  [key: string]: SupperCustomizationOptionsType[];
};

// export interface ProductAddOn extends CustomizationElementType{
//   id: string;
//   name: string;
//   isStandard: boolean;
//   hasExtra: boolean;
//   isChangeable: boolean;
//   isRemovable: boolean;
//   isAvailable: boolean;
//   price: number;
//   itemTypeId: string;
//   itemType: string;
//   size: string;
// }

export interface FavoriteOrderItems extends ProductEntity {
  productAddOns: CustomizationElementType[];
}

export type customizationOptionResponse = {
  halfBakedItem: boolean;
  subProducts: CustomizationOptionsType[];
};

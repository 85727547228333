import { usePost } from "~/composables/api/customFetch/useCustomFetch";
import type { Order } from "~/types/entities/payable.type";
import type {
  checkoutResponse,
  ValidateCartItem,
  ValidateProductResponse,
} from "~/types/entities/cartValidation.type";

export default function useCheckoutApi() {
  const validateCartItem = async (
    payload: ValidateCartItem,
    couponCode = false,
  ): Promise<ValidateProductResponse | null> => {
    try {
      const { data } = await usePost<ValidateProductResponse>(
        couponCode ? "shoppingcart/apply-discount" : "/shoppingcart/update-v2",
        {
          body: payload,
          withToken: true,
        },
      );
      return data.value ?? null;
    } catch (e) {
      console.error(e);
      return null;
    }
  };
  const proceedToCheckout = async (orderData: Order): Promise<checkoutResponse | null> => {
    try {
      const { data } = await usePost<checkoutResponse>("/payment/checkout-v2", {
        body: orderData,
        withToken: true,
      });
      return data.value;
    } catch (e) {
      console.error(e);
      return null;
    }
  };
  return {
    proceedToCheckout,
    validateCartItem,
  };
}

import { defineStore, storeToRefs } from "pinia";
import type { RestaurantType } from "~/types/entities/restaurant";
import { useMenuSlice } from "~/store/menuSlice";
import { useCartSlice } from "~/store/cartSlice";
import { OrderMethod, useOrderMethodStore } from "~/store/orderMethodSlice";

interface LocationSliceState {
  locationPermission: boolean;
  userLocation: { lat: number; lng: number } | null;
  locationSelectionModalShow: boolean;
  selectedRestaurant: RestaurantType | null;
}

export const useLocationSlice = defineStore("locationSlice", {
  state: (): LocationSliceState => {
    return {
      locationPermission: true,
      userLocation: null,
      locationSelectionModalShow: false,
      selectedRestaurant: null,
    };
  },
  actions: {
    toggleLocationSelectionModal() {
      const orderMethodStore = useOrderMethodStore();
      const { selectedMethod, selectedLocation } = storeToRefs(orderMethodStore);
      if (
        this.locationSelectionModalShow &&
        selectedMethod.value === OrderMethod.DELIVERY &&
        !selectedLocation.value
      ) {
        orderMethodStore.setOrderMethod(OrderMethod.PICKUP);
      }
      this.locationSelectionModalShow = !this.locationSelectionModalShow;
    },
    setCurrentUserLocation(lat: number, lng: number) {
      this.userLocation = { lat, lng };
    },
    requestGeolocationPermission() {
      if (navigator && navigator.geolocation) {
        try {
          navigator.geolocation.getCurrentPosition((currentPosition) => {
            this.setCurrentUserLocation(
              currentPosition.coords.latitude,
              currentPosition.coords.longitude,
            );
          });
        } catch {
          this.locationPermission = false;
        }
      }
    },
    setSelectedRestaurant(selectedRestaurant: RestaurantType | null) {
      const { proceedAutoScheduleIfNeeded } = useAutoSchedule();
      this.selectedRestaurant = selectedRestaurant;
      proceedAutoScheduleIfNeeded();
      const menuSlice = useMenuSlice();
      menuSlice.getAllProductsByName();

      // Validate cart items for newly selected restaurant
      const cartSlice = useCartSlice();
      if (cartSlice.cartList.length > 0) {
        cartSlice.validateCartItem();
      }
    },
  },
  persist: {
    storage: persistedState.localStorage,
  },
});

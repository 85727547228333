import { useGet } from "~/composables/api/customFetch/useCustomFetch";
import type {
  OrderDetailsResponse,
  OrderRegistrationResponse,
} from "~/types/entities/orderConfirmation";

export function useOrderConfirmation() {
  const registerOrderToStash = async (ref: string, code: string, userId: string) => {
    try {
      const { data } = await useGet<OrderRegistrationResponse>(
        `/payment/receivestatus/${ref}/${code}/${userId}`,
        { withToken: true },
      );
      return data.value ?? null;
    } catch (e) {
      useNuxtApp().$Sentry.captureException(
        `order confirmation failed, ref: ${ref}, code: ${code}, userId: ${userId}`,
      );
      console.error(e);
      return null;
    }
  };
  const getOrderStatus = async (orderId: number) => {
    try {
      const { data } = await useGet<OrderDetailsResponse>(`/payment/order/${orderId}`, {
        withToken: true,
      });
      return data.value ?? null;
    } catch (e) {
      console.log(e);
      return null;
    }
  };
  const registerAndGetStatus = async (
    ref: string,
    code: string,
    userId: string,
  ): Promise<OrderDetailsResponse | null> => {
    try {
      const orderConfStatus = await registerOrderToStash(ref, code, userId);
      return await getOrderStatus(orderConfStatus?.stasOrderId || 0);
    } catch (e) {
      console.error(e);
      return null;
    }
  };
  const getLiveOrders = async () => {
    try {
      const { data } = await useGet<number[]>("/payment/orders/live", {
        withToken: true,
        disableGlobalLoader: true,
      });
      return data.value ?? [];
    } catch (e) {
      console.log(e);
      return [];
    }
  };

  const sendInvoiceOfOrderInEmail = async (orderId: number) => {
    try {
      await useGet<string>("/payment/sendEmail", {
        withToken: true,
        params: {
          orderId,
        },
      });
      return "emailSent";
    } catch (e) {
      console.log(e);
      return "";
    }
  };

  return {
    registerOrderToStash,
    getOrderStatus,
    registerAndGetStatus,
    getLiveOrders,
    sendInvoiceOfOrderInEmail,
  };
}

import { defineStore } from "pinia";
import type { NavigatorType } from "~/types/entities/navigatorProducts.type";
import useMenu from "~/composables/api/mock/useMenu";
import type { ProductEntity, ProductsResponse } from "~/types/entities/product.type";
import type { RestaurantType } from "~/types/entities/restaurant";

interface menuSliceState {
  queryParams: {
    [key: string]: string;
  };
  productsNavigator: NavigatorType[];
  categoriesNavigator: string[];
  productsLists: ProductsResponse[];
  restaurants: RestaurantType[];
  recommendedProducts: ProductEntity[];
}

export const useMenuSlice = defineStore("menuSlice", {
  state: (): menuSliceState => {
    const route = useRoute();
    const initialQueryParams = (route.query ?? {}) as { [key: string]: string };
    return {
      queryParams: initialQueryParams,
      productsNavigator: [],
      categoriesNavigator: [],
      productsLists: [],
      restaurants: [],
      recommendedProducts: [],
    };
  },
  actions: {
    setQueryParams(key: string, value: string) {
      this.queryParams[key] = value;
    },
    clearParams() {
      this.queryParams = {};
    },
    async getAllProductsNavigator() {
      const { getAllProductsName } = useMenu();
      this.productsNavigator = await getAllProductsName();
    },
    async getAllProductsByName() {
      const { getAllProductsByName } = useMenu();
      let productTypeId;
      if (this.queryParams.product) {
        productTypeId =
          this.$state.productsNavigator.find(
            (item) => item.categoryName === this.queryParams.product,
          )?.categoryId || "";
      } else {
        productTypeId =
          this.$state.productsNavigator.find((item) => item.isDefault)?.categoryId || "";
      }
      this.productsLists = await getAllProductsByName(productTypeId);
    },
    async getAllRestaurants() {
      const { getAllRestaurantCoordinate } = useMenu();
      this.restaurants = await getAllRestaurantCoordinate();
    },
    async getAllRecommendedProduct() {
      const { getUpSaleProduct } = useMenu();
      this.recommendedProducts = await getUpSaleProduct();
    },
  },
  getters: {
    subCategories(state) {
      return state.productsLists.map((item) => item.subCategoryName);
    },
  },
});
